import { Form, Select, Modal } from 'antd';
import { renderModal, destroyAllModal } from 'egenie-common';
import type { ValueAndLabelData, BatchReportData } from 'egenie-utils';
import { request, BatchReport } from 'egenie-utils';
import React from 'react';

interface Props {
  entityIds: string;
  expressData: ValueAndLabelData;
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback?: Function;
}

export function BatchModifyExpress(props: Props) {
  const {
    callback,
    entityIds,
    expressData,
  } = props;

  const [form] = Form.useForm();
  const [
    data,
    setData,
  ] = React.useState({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (data.params) {
      request<BatchReportData>({
        url: '/api/cloud/wms/rest/order/v2/courierForwardBatch',
        method: 'POST',
        data: {
          ...data.params,
          entityIds,
        },
      })
        .then((info) => {
          destroyAllModal();
          renderModal(
            <BatchReport
              {...info.data}
              columns={[
                {
                  title: '发货单编号',
                  dataIndex: 'orderNo',
                },
                {
                  title: '失败原因',
                  dataIndex: 'operationResult',
                },
              ]}
            />
          );
          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch(() => setData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    data,
    entityIds,
  ]);

  return (
    <Modal
      cancelText="取消"
      okButtonProps={{ loading: data.loading }}
      okText="保存"
      onCancel={destroyAllModal}
      onOk={() => form.submit()}
      title="批量修改快递公司"
      visible
      width={400}
    >
      <Form
        form={form}
        labelAlign="right"
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="快递公司"
          name="courierId"
          rules={[
            {
              required: true,
              message: '请选择快递公司',
            },
          ]}
        >
          <Select
            optionFilterProp="label"
            options={expressData}
            placeholder="请选择快递公司"
            showSearch
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
