import type { PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import React from 'react';
import type { BoxItem } from './types';
import type { Store } from './index';

export function boxDetailGrid(context: Store) {
  return {
    tab: {
      name: '箱单',
      value: 'box-detail',
    },
    grid: {
      columns: [
        {
          key: 'wmsOrderBoxNo',
          name: '箱单号',
          width: 100,
        },
        {
          key: 'totalNumber',
          name: 'SKU数量',
          formatter: ({ row }) => (
            <a onClick={() => {
              context.boxDetailStore.handleOpen(row.wmsOrderBoxNo);
            }}
            >
              {row.totalNumber}
            </a>
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/invoice/index/box',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<BoxItem>>({
          url: '/api/cloud/wms/rest/box/list/page',
          method: 'POST',
          data: {
            wmsOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
        });
      },
    },
  };
}
