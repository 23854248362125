import { message, Modal } from 'antd';
import { printWayBill, renderModal, destroyAllModal, destroyModal } from 'egenie-common';
import type { BaseData, BatchReportData } from 'egenie-utils';
import { BatchReport, request } from 'egenie-utils';
import { values } from 'mobx';
import React from 'react';
import { BatchModifyExpress } from './batchModifyExpress';
import type { InvoiceItem } from './types';
import type { Store } from './index';
import _ from 'lodash';
import { ForwardModal } from './forwardModal';
import {BatchModifyCourierNo} from "./batchModifyCourierNo";

export function mainTableButton(context: Store) {
  return [
    {
      permissionId: '704',
      text: '获取单号',
      icon: 'icon-get_order',
      handleClick: () => {
        const ids = context.gridModel.gridModel.selectRows.map((item) => item.id)
          .join(',');
        if (!ids) {
          message.warning('请选择');
          return;
        }

        Modal.confirm({
          content: '确认获取?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/waybill/getWaybill',
            method: 'post',
            data: { wms_order_id: ids },
          })
            .then(() => {
              message.success('获取成功');
              context.gridModel.onQuery();
            }),
        });
      },
    },
    {
      permissionId: '704',
      text: '重新获取单号',
      icon: 'icon-get_order',
      handleClick: () => {
        const ids = context.gridModel.gridModel.selectRows.map((item) => item.id)
          .join(',');

        if (!ids) {
          message.warning('请选择');
          return;
        }

        Modal.confirm({
          title: '确认要重新获取电子面签单吗?',
          content: `应用场景：当更换网点后，重新获取单号将根据最新设置的网点更换新单号。
              注意事项：更换新单号后将会自动同步平台上的快递单号，是否成功取决于是否在平台约定的有效期内；`,
          onOk: () => request<BaseData<string>>({
            url: '/api/cloud/wms/rest/waybill/resetWaybill',
            method: 'POST',
            data: { ids },
          })
            .then((info) => {
              message.success(info.data || '重新获取成功');
              context.gridModel.onQuery();
            }),
        });
      },
    },
    {
      permissionId: '705',
      text: '打印面单',
      icon: 'icon-print',
      handleClick: () => {
        const ids = context.gridModel.gridModel.selectRows.map((item) => item.id)
          .join(',');

        if (!ids) {
          message.warning('请选择');
          return;
        }

        printWayBill.frontPrint({
          tempType: 0,
          preview: false,
          userDataIds: ids,
        });
      },
    },
    {
      permissionId: '706',
      text: '选模板打印面单',
      icon: 'icon-print',
      handleClick: () => {
        const ids = context.gridModel.gridModel.selectRows.map((item) => item.id)
          .join(',');

        if (!ids) {
          message.warning('请选择');
          return;
        }

        printWayBill.customPrint({
          userDataIds: ids,
          printSrc: '1',
          checkPrint: true,
        });
      },
    },
    {
      permissionId: '707',
      text: '修改快递',
      icon: 'icon-print',
      handleClick: () => {
        const ids = context.gridModel.gridModel.selectRows.map((item) => item.id)
          .join(',');

        if (!ids) {
          message.warning('请选择');
          return;
        }

        if (context.programme.filterItems.dict.courierIds?.length) {
          renderModal(
            <BatchModifyExpress
              callback={() => context.programme.gridModel.onRefresh()}
              entityIds={ids}
              expressData={context.programme.filterItems.dict.courierIds || []}
            />
          );
        } else {
          message.warning('请先获取快递公司');
        }
      },
    },
    {
      permissionId: '707',
      text: '手动填运单号',
      icon: 'icon-print',
      handleClick: () => {
        const ids = context.gridModel.gridModel.selectRows.map((item) => item.id)
          .join(',');

        if (!ids) {
          message.warning('请选择');
          return;
        }
        renderModal(
          <BatchModifyCourierNo
            callback={() => context.programme.gridModel.onRefresh()}
            entityIds={ids}
          />
        );
      },
    },
    {
      text: '导出',
      icon: 'icon-export',
      handleClick: () => {
        const ids = values(context.programme.gridModel.gridModel.selectedIds)
          .join(',');
        const fileName = '发货单';
        const exportType = 'cloud_wms_order';
        const queryParam = {
          ...context.programme.filterItems.params,
          warehouseId: context.programme.filterItems.params.warehouseIds,
        };
        const queryParamShow = context.programme.filterItems.translateParams.join(' ');

        if (ids) {
          context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
        } else {
          Modal.confirm({
            title: '提示',
            content: '未选择数据将导出全部数据?',
            onOk: () => {
              context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
            },
          });
        }
      },
    },
    {
      permissionId: '96',
      text: '取消发货',
      icon: 'icon-print',
      handleClick: () => {
        const selectRows: InvoiceItem[] = context.gridModel.gridModel.selectRows;

        if (selectRows.length === 0) {
          message.warning({
            key: '请至少选择一条数据',
            content: '请至少选择一条数据',
          });
          return;
        }

        if (selectRows.some((item) => item.courierPrintMarkState !== 2 || item.examineGoodsStateCode !== 1)) {
          message.warning({
            key: '仅已打印的且验货状态已验货的发货单可以取消发货',
            content: '仅已打印的且验货状态已验货的发货单可以取消发货',
          });
          return;
        }

        Modal.confirm({
          title: '确认取消发货吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/order/v2/cancel/delivery',
            method: 'POST',
            data: {
              ids: selectRows.map((item) => item.id)
                .join(','),
            },
          })
            .then(() => {
              message.success('取消发货成功');
              context.gridModel.gridModel.resetAll();
              context.gridModel.onRefresh();
            }),
        });
      },
    },
    {
      permissionId: '4074',
      text: '智链加工单',
      handleClick: () => {
        const selectRows: InvoiceItem[] = context.gridModel.gridModel.selectRows;

        if (selectRows.length === 0) {
          message.warning({
            key: '请至少选择一条数据',
            content: '请至少选择一条数据',
          });
          return;
        }

        if (selectRows.some((item) => item.printPatternStatus !== 2)) {
          message.warning({
            key: '请勾选烫印状态为: 待烫印的数据',
            content: '请勾选烫印状态为: 待烫印的数据',
          });
          return;
        }

        Modal.confirm({
          title: '确认智链加工吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/order/v2/pushProcessBill',
            method: 'POST',
            data: { wmsOrderIds: selectRows.map((item) => item.id) },
          })
            .then(() => {
              message.success('智链加工成功');
              context.gridModel.gridModel.resetAll();
              context.gridModel.onRefresh();
            }),
        });
      },
    },
    {
      permissionId: '4078',
      text: '手工验货',
      handleClick: () => {
        const selectedIds = Array.from(context.gridModel.gridModel.selectedIds);

        if (selectedIds.length === 0) {
          const error = '请至少选择一条数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          title: '确认手工验货吗?',
          onOk: () => request<BatchReportData>({
            url: '/api/cloud/wms/rest/order/v2/manualCheck',
            method: 'POST',
            data: { wmsOrderIds: selectedIds },
          })
            .then((info) => {
              destroyModal();
              renderModal(
                <BatchReport
                  {...info.data}
                  columns={[
                    {
                      title: '发货单编号',
                      dataIndex: 'orderNo',
                    },
                    {
                      title: '失败原因',
                      dataIndex: 'operationResult',
                    },
                  ]}
                />
              );
              message.success('手工验货成功');
              context.gridModel.gridModel.resetAll();
              context.gridModel.onRefresh();
            }),
        });
      },
    },
    {
      permissionId: '166',
      text: '重新发货',
      handleClick: () => {
        const selectRows: InvoiceItem[] = context.gridModel.gridModel.selectRows;

        if (selectRows.length === 0) {
          message.warning({
            key: '请至少选择一条数据',
            content: '请至少选择一条数据',
          });
          return;
        }

        if (!selectRows.every((item) => item.courierPrintMarkState === 2)) {
          const error = '仅已打印的发货单可点击重新发货';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          title: '确认重新发货吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/order/v2/redelivery',
            method: 'POST',
            data: {
              wmsOrderIds: selectRows.map((item) => item.id)
                .join(','),
            },
          })
            .then(() => {
              message.success('重新发货成功');
              context.gridModel.gridModel.resetAll();
              context.gridModel.onRefresh();
            }),
        });
      },
    },
    {
      permissionId: '25',
      text: '作废',
      handleClick: () => {
        const selectRows: InvoiceItem[] = context.gridModel.gridModel.selectRows;

        if (selectRows.length === 0) {
          message.warning({
            key: '请至少选择一条数据',
            content: '请至少选择一条数据',
          });
          return;
        }

        if (!selectRows.every((item) => item.stopState == 1 && item.cancelFlag == 0)) {
          message.warning({
            key: '仅已挂起未作废的发货单可以作废',
            content: '仅已挂起未作废的发货单可以作废',
          });
          return;
        }

        Modal.confirm({
          title: '作废后将无法发货,是否继续?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/order/v2/cancel',
            method: 'POST',
            data: {
              wmsOrderIds: selectRows.map((item) => item.id)
                .join(','),
            },
          })
            .then(() => {
              message.success('作废成功');
              context.gridModel.gridModel.resetAll();
              context.gridModel.onRefresh();
            }),
        });
      },
    },
    {
      text: '转单发货',
      permissionId: '6004',
      handleClick: () => {
        const selectRows: InvoiceItem[] = context.gridModel.gridModel.selectRows;

        if (selectRows.length === 0) {
          const error = '请至少选择一条数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!selectRows.every((item) => item.courierPrintMarkState === 1 && !item.wmsPickingOrderNo && !item.suspended)) {
          const error = '请选择未打印、未进波次、未停发的发货单';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        const warehouseId = _.uniqBy(selectRows, 'warehouseId').map((item) => item.warehouseId);
        const warehouseIdStr = warehouseId.filter(Boolean).join(',');
        const ownerId = _.uniqBy(selectRows, 'ownerId').map((item) => item.ownerId);
        const ownerIdStr = ownerId.filter(Boolean).join(',');
        const buyerTenantId = _.uniqBy(selectRows, 'buyerTenantId').map((item) => item.buyerTenantId);
        const buyerTenantIdStr = buyerTenantId.filter(Boolean).join(',');
        const saleOrderOriginType = _.uniqBy(selectRows, 'saleOrderOriginType').map((item) => item.saleOrderOriginType);
        const saleOrderOriginTypeStr = saleOrderOriginType.filter(Boolean).join(',');
        const wmsOrderIds = selectRows.map((item) => item.id).join(',');

        if (!(warehouseId.length === 1 && ownerId.length === 1 && buyerTenantId.length === 1 && saleOrderOriginType.length === 1)) {
          const error = '请选择单仓库,单货主数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        renderModal(
          <ForwardModal
            buyerTenantId={buyerTenantIdStr}
            callback={(params) => request({
              url: '/api/cloud/wms/rest/order/v2/transfer',
              method: 'POST',
              headers: { warehouseId: warehouseIdStr || '' },
              data: {
                ownerId: ownerIdStr,
                wmsOrderIds,
                ...params,
              },
            })
              .then(() => {
                message.success('转单转单成功');
                destroyAllModal();

                context.programme.gridModel.gridModel.clearToOriginal();
                context.programme.gridModel.onQuery();
              })}
            onCancel={destroyAllModal}
            orderType={saleOrderOriginTypeStr}
            ownerId={ownerIdStr}
            warehouseId={warehouseIdStr}
          />
        );
      },
    },
  ];
}
