import { Space } from 'antd';
import React from 'react';
import styles from './index.less';
import type { InvoiceItem } from './types';

export function formatterMark({ row }: { row: InvoiceItem; }) {
  const {
    stopMark,
    cancelMark,
    printedMark,
    priority,
    cancelDelivery,
    suspended,
    deadlineLogisticsMark,
  } = row;
  return (
    <Space
      size={2}
      style={{ height: '100%' }}
    >
      {
        printedMark ? (
          <span className={styles.item}>
            {printedMark}
          </span>
        ) : null
      }
      {
        cancelMark ? (
          <span className={styles.item}>
            废
          </span>
        ) : null
      }
      {
        stopMark ? (
          <span className={styles.item}>
            挂
          </span>
        ) : null
      }
      {
        priority ? (
          <span className={styles.item}>
            先
          </span>
        ) : null
      }
      {
        cancelDelivery ? (
          <span className={styles.item}>
            取消
          </span>
        ) : null
      }
      {
        suspended ? (
          <span className={styles.item}>
            停
          </span>
        ) : null
      }
      {
        deadlineLogisticsMark ? (
          <span className={styles.item}>
            超
          </span>
        ) : null
      }
    </Space>
  );
}
