import { request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';

export function logSubGrid(logBizModuleCode: string | number, extraParams?: any, warehouseIdField?: string) {
  return {
    tab: {
      name: '日志',
      value: 'log',
    },
    grid: {
      columns: [
        {
          key: 'moduleType',
          name: '操作模块',
          width: 150,
        },
        {
          key: 'operationType',
          name: '操作名称',
          width: 150,
        },
        {
          key: 'operationResult',
          name: '操作结果',
        },
        {
          key: 'createTime',
          name: '操作时间',
          width: 200,
          formatter: ({ row }) => {
            return <TimeStampFormatter value={row.createTime}/>;
          },
        },
        {
          key: 'operatorShowName',
          name: '操作人',
          width: 100,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'logBizRecordId',
      showEmpty: false,
      sortByLocal: false,
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
    },
    api: {
      onQuery: ({
        data,
        pid,
        cursorRow,
      }) => {
        const {
          page,
          pageSize,
        } = data;
        return request({
          url: '/api/cloud/log/listPage',
          method: 'POST',
          data: {
            logBizModuleCode,
            page,
            pageSize,
            entityId: pid,
            ...extraParams,
          },
          headers: { warehouseId: cursorRow?.[warehouseIdField] || '' },
        });
      },
    },
  };
}
