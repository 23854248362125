import { Modal } from 'antd';
import { MainSubStructure, MainSubStructureModel, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

export class BoxDetailModel {
  @observable public wmsOrderBoxNo?: string ;

  @observable public open?: boolean = false ;

  @action
  public handleCancel = () => {
    this.open = false;
    this.mainSubStructureModel.gridModel.resetAll();
  };

  @action
  public handleOpen = (wmsOrderBoxNo?: string) => {
    this.open = true;
    this.wmsOrderBoxNo = wmsOrderBoxNo;
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [],
    hiddenSubTable: true,
    grid: {
      columns: [
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 100,
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
        },
      ].map((item) => ({
        ...item,
        resizable: true,
        draggable: false,
      })),
      rows: [],
      pageSize: 50,
      primaryKeyField: 'skuNo',
      sortByLocal: false,
      showCheckBox: false,
      showSelectedTotal: false,
      showPager: true,
      showPagination: true,
      showRefresh: true,
    },
    api: {
      onQuery: (params) => {
        const {
          ...rest
        } = params;
        return request({
          method: 'POST',
          url: '/api/cloud/wms/rest/box/detail/page',
          data: {
            wmsOrderBoxNo: this.wmsOrderBoxNo,
            ...rest,
          },
        });
      },
    },
  });
}

export const BoxDetailModal = observer(({ store }: { store: BoxDetailModel; }) => {
  const { open, handleCancel, mainSubStructureModel } = store;
  return (
    <Modal
      footer={null}
      onCancel={handleCancel}
      open={open}
      title="箱单详情"
      width={600}
    >
      <div style={{ height: 400 }}>
        <MainSubStructure store={mainSubStructureModel}/>
      </div>

    </Modal>
  );
});
