import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { InvoiceDetailItem } from './types';
import { Button } from 'antd';
import type { Store } from './index';

export function invoiceDetailGrid(context: Store) {
  return {
    tab: {
      name: '发货单明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'hotStampMark',
          name: '标记',
          width: 100,
          formatter: ({ row }) => {
            return (
              <Button
                onClick={() => context.ironPrintDetailModalStore.handleOpen(row)}
                size="small"
                type="link"
              >
                {row.hotStampMark}
              </Button>
            );
          },
        },
        {
          key: 'productName',
          name: '商品名称',
          width: 150,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 150,
          sortable: true,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
          sortable: true,
        },
        {
          key: 'barCode',
          name: '条形码',
          width: 150,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 150,
        },
        {
          name: '供应商',
          key: 'vendorNo',
          width: 150,
        },
        {
          key: 'supplierName',
          name: '库存归属',
          width: 150,
        },
        {
          key: 'sellerOuterNo',
          name: '货号',
          width: 150,
        },
        {
          key: 'warehouseBinNo',
          name: '库位',
          width: 150,
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
          width: 150,
        },
        {
          key: 'uniqueCodeStatusName',
          name: '唯一码状态',
          width: 120,
        },
        {
          key: 'num',
          name: '数量',
          width: 150,
        },
        {
          key: 'waveCompletedState',
          name: '分拣状态',
          width: 150,
        },
        {
          key: 'outOfStock',
          name: '上架状态',
          width: 150,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => {
            return <ImgFormatter value={row.pic}/>;
          },
        },
        {
          key: 'spec',
          name: '规格',
          width: 100,
        },
        {
          key: 'onlineSpec',
          name: '网店规格',
          width: 150,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'weight',
          name: '重量',
          width: 90,
        },
        {
          key: 'printPatternFlag',
          name: '印花款',
          width: 100,
        },
        {
          key: 'costPrice',
          name: '成本价',
          width: 80,
        },
        {
          key: 'pledgeNum',
          name: '质押库存',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/invoice/index/detail',
    },
    api: {
      onQuery: ({
        data,
        pid,
        cursorRow,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<InvoiceDetailItem>>({
          url: '/api/cloud/wms/rest/orderDetail/v2/list/page',
          method: 'POST',
          data: {
            wmsOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
          headers: { warehouseId: cursorRow?.warehouseId || '' },
        });
      },
    },
  };
}
