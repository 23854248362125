import { Input, message, Row, Tag } from 'antd';
import { request } from 'egenie-common';
import _ from 'lodash';
import { Observer } from 'mobx-react';
import React from 'react';
import { ENUM_PLUS_SERVICE_TYPE } from '../../utils';
import { ExpressSearch } from './expressSearch';
import { formatterMark } from './formatterMark';
import type { InvoiceItem } from './types';
import type { Store } from './index';
import { TimeStampFormatter } from 'egenie-utils';

export function mainTableColumns(context: Store) {
  return [
    {
      key: 'stopMark',
      name: '标记',
      width: 120,
      formatter: formatterMark,
    },
    {
      key: 'printExpressNum',
      name: '快递单打印次数',
      width: 120,
      sortable: true,
    },
    {
      key: 'courierPrintShowSeq',
      name: '快递单打印序号',
      width: 120,
    },
    {
      key: 'courierName',
      name: '快递公司',
      width: 120,
    },
    {
      key: 'wmsOrderNo',
      name: '发货单编号',
      width: 200,
      sortable: true,
    },
    {
      key: 'examineGoodsState',
      name: '验货状态',
      width: 90,
    },
    {
      key: 'cancel',
      name: '作废状态',
      width: 90,
    },
    {
      key: 'wmsOrderState',
      name: '系统发货状态',
      width: 130,
    },
    {
      key: 'orderType',
      name: '订单类型',
      width: 130,
    },
    {
      key: 'courierNo',
      name: '快递单号',
      width: 200,
      formatter: ({ row }) => {
        return (
          <Row
            align="middle"
            justify="space-between"
            style={{
              cursor: 'pointer',
              width: '100%',
              height: '100%',
            }}
          >
            {row.courierNo}
            {row.courierNo ? (
              <ExpressSearch
                expressName={row.courierName}
                expressNumber={row.courierNo}
              />
            ) : null}
          </Row>
        );
      },
    },
    {
      key: 'platformTypeName',
      name: '平台类型',
      width: 100,
    },
    {
      key: 'platformOrderCode',
      name: '平台单号',
      width: 200,
    },
    {
      key: 'saleOrderNo',
      name: '订单编号',
      width: 200,
    },
    {
      key: 'prescriptionType',
      name: '订单时效',
      width: 120,
    },
    {
      key: 'shopName',
      name: '店铺',
      width: 150,
    },
    {
      key: 'sellerMemo',
      name: '客服备注',
      width: 150,
    },
    {
      key: 'saleOrderMemo',
      name: '订单备注',
      width: 150,
    },
    {
      key: 'courierPrintMarkStateStr',
      name: '快递单打印状态',
      width: 90,
    },
    {
      key: 'wmsPickingOrderNo',
      name: '波次号',
      width: 160,
    },
    {
      key: 'warehouseName',
      name: '仓库',
      width: 150,
    },
    {
      key: 'ownerName',
      name: '货主',
      width: 150,
      sortable: true,
      sidx: 'ownerId',
    },
    {
      key: 'weight',
      name: '重量',
      width: 90,
    },
    {
      key: 'saleOrderOriginType',
      name: '发货单来源',
      width: 120,
    },
    {
      key: 'wave',
      name: '波次状态',
      width: 120,
    },
    {
      key: 'cellNo',
      name: '格子号',
      width: 90,
    },
    {
      key: 'totalNum',
      name: '总件数',
      width: 90,
      sortable: true,
    },
    {
      key: 'totalSku',
      name: '总条数',
      width: 90,
      sortable: true,
    },
    {
      key: 'createTime',
      name: '创建时间',
      width: 150,
      sortable: true,
      formatter: ({ row }: { row: InvoiceItem; }) => <TimeStampFormatter value={row.createTime}/>,
    },
    {
      key: 'payTime',
      name: '付款时间',
      width: 150,
      sortable: true,
      formatter: ({ row }: { row: InvoiceItem; }) => <TimeStampFormatter value={row.payTime}/>,
    },
    {
      key: 'courierPrintTime',
      name: '快递单打印时间',
      width: 150,
      sortable: true,
      formatter: ({ row }: { row: InvoiceItem; }) => <TimeStampFormatter value={row.courierPrintTime}/>,
    },
    {
      key: 'examineGoodsTime',
      name: '验货时间',
      width: 150,
      sortable: true,
      formatter: ({ row }: { row: InvoiceItem; }) => <TimeStampFormatter value={row.examineGoodsTime}/>,
    },
    {
      key: 'weightTime',
      name: '称重时间',
      width: 150,
      sortable: true,
      formatter: ({ row }: { row: InvoiceItem; }) => <TimeStampFormatter value={row.weightTime}/>,
    },
    {
      key: 'deadlineLogisticsTime',
      name: '截止发货时间',
      width: 150,
      sortable: true,
      formatter: ({ row }: { row: InvoiceItem; }) => <TimeStampFormatter value={row.deadlineLogisticsTime}/>,
    },
    {
      key: 'outOfStock',
      name: '上架状态',
      width: 90,
    },
    {
      key: 'suspended',
      name: '是否停发',
      width: 80,
      formatter: ({ row }) => {
        return (
          <span>
            {row.suspended ? '已停发' : '未停发'}
          </span>
        );
      },
    },
    {
      key: 'addedValueService',
      name: '增值服务',
      width: 300,
      formatter: ({ row }) => {
        return (Array.isArray(row.addedValueService) ? row.addedValueService : []).map((val) => (
          <Tag
            color="blue"
            key={val}
          >
            {Object.values(ENUM_PLUS_SERVICE_TYPE).find((labelData) => labelData.value === _.toString(val))?.label}
          </Tag>
        ));
      },
    },
    {
      key: 'cancelDelivery',
      name: '是否取消发货',
      width: 100,
      formatter: ({ row }) => {
        return (
          <span>
            {row.cancelDelivery ? '是' : '否'}
          </span>
        );
      },
    },
    {
      key: 'platformDeliverStatus',
      name: '平台发货状态',
      width: 100,
      formatter: ({ row }) => {
        return (
          <span>
            {row.platformDeliverStatus ? '已发货' : '未发货'}
          </span>
        );
      },
    },
    {
      key: 'platformCourierNo',
      name: '平台快递单号',
      width: 200,
    },
    {
      key: 'printPatternStatusStr',
      name: '烫印状态',
      width: 100,
    },
    {
      key: 'outOfStockNum',
      name: '上架数量',
      width: 100,
    },
    {
      name: '备注',
      key: 'note',
      width: 200,
      formatter: ({
        rowIdx,
        row,
      }: { rowIdx: number; row: InvoiceItem; }) => {
        return (
          <Observer>
            {
              () => (
                <Input
                  onBlur={() => {
                    request({
                      url: '/api/cloud/wms/rest/order/v2/updateNoteByOrderId',
                      method: 'post',
                      data: {
                        wmsOrderId: row.id,
                        newNote: context.gridModel.gridModel.rows[rowIdx].note,
                      },
                    })
                      .then(() => {
                        message.success({
                          key: '备注修改成功',
                          content: '备注修改成功',
                        });
                      });
                  }}
                  onChange={(event) => context.gridModel.gridModel.rows[rowIdx].note = event.target.value}
                  onClick={(event) => event.stopPropagation()}
                  size="small"
                  value={context.gridModel.gridModel.rows[rowIdx].note}
                />
              )
            }
          </Observer>
        );
      },
    },
    {
      key: 'courierPrintModeStr',
      name: '打印方式',
      width: 100,
    },
    {
      key: 'waybillCodeSourceStr',
      name: '单号获取来源',
      width: 100,
    },
    {
      key: 'deliveryWarnStr',
      name: '有货超时',
      width: 100,
    },
    {
      key: 'buyerTenantName',
      name: '代发客户',
      width: 100,
    },
  ].map((info) => ({
    resizable: true,
    sortable: false,
    ...info,
  }));
}

