import { Col, Row, Typography } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, set } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { ReceivePersonItem } from './types';

const data = observable<ReceivePersonItem & { isShowPhone: boolean; isShowMobile: boolean; }>({
  isShowPhone: false,
  isShowMobile: false,
});

@observer
class ReceivePerson extends React.Component {
  render() {
    return (
      <div className={styles.receivePersonContainer}>
        <Row className={styles.itemContainer}>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              收货人
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.receiverName}
              >
                {data.receiverName}
              </Typography.Text>
            </section>
          </Col>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              手机号码
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.isShowPhone ? data.receiverPhone : ''}
              >
                {data.isShowPhone ? data.receiverPhone : ''}
              </Typography.Text>
              <i
                className={`${styles.icon} icon-d_inspect`}
                onClick={() => set(data, { isShowPhone: !data.isShowPhone })}
              />
            </section>
          </Col>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              固定号码
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.isShowMobile ? data.receiverMobile : ''}
              >
                {data.isShowMobile ? data.receiverMobile : ''}
              </Typography.Text>
              <i
                className={`${styles.icon} icon-d_inspect`}
                onClick={() => set(data, { isShowMobile: !data.isShowMobile })}
              />
            </section>
          </Col>
          <Col
            className={`${styles.receivePersonItem}`}
            span={6}
          >
            <span className={styles.receivePersonTitle}>
              邮编
            </span>
            <section style={{ width: 'calc(100% - 100px)' }}>
              <Typography.Text
                ellipsis
                title={data.receiverZip}
              >
                {data.receiverZip}
              </Typography.Text>
            </section>
          </Col>
        </Row>
        <div className={styles.itemContainer}>
          <span className={styles.receivePersonTitle}>
            收货地址
          </span>
          <Row
            gutter={[
              10,
              0,
            ]}
            style={{ flexGrow: 1 }}
          >
            {
              [
                'receiverState',
                'receiverCity',
                'receiverDistrict',
                'receiverTown',
              ].map((item, _, array) => {
                return (
                  <Col
                    className={`${styles.receivePersonItem}`}
                    key={item}
                    span={24 / array.length}
                  >
                    <section className={styles.receiverName}>
                      <Typography.Text
                        ellipsis
                        title={data[item]}
                      >
                        {data[item]}
                      </Typography.Text>
                      <i className={`${styles.icon} icon-arrow_pulldown`}/>
                    </section>
                  </Col>
                );
              })
            }
          </Row>
        </div>
        <div className={styles.itemContainer}>
          <span className={styles.receivePersonTitle}/>
          <Row style={{ width: 'calc(100% - 70px)' }}>
            <Col
              className={`${styles.receivePersonItem}`}
              span={24}
            >
              <section className={styles.receiverName}>
                <Typography.Text
                  ellipsis
                  title={data.receiverAddress}
                >
                  {data.receiverAddress}
                </Typography.Text>
              </section>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export function receivePerson() {
  return {
    tab: {
      name: '收货人信息',
      value: 'receivePerson',
    },
    isCustom: true,
    customView: ReceivePerson,
    api: {
      onQuery: ({ pid, cursorRow }) => {
        if (pid) {
          return request<BaseData<ReceivePersonItem>>({
            url: `/api/cloud/wms/rest/order/receiver/v1/${pid}`,
            headers: { warehouseId: cursorRow?.warehouseId || '' },
          })
            .then((info) => set(data, info.data || {}));
        } else {
          return Promise.reject();
        }
      },
    },
  };
}
