import { objToDict } from 'egenie-common';
import type { DictData } from 'egenie-common';
import type { BaseData, PaginationData, FilterConfigData } from 'egenie-utils';
import { request, MainSubStructureModel, Programme, ProgrammeComponent, ExportStore, ExportModal, formatFilterConfigData } from 'egenie-utils';
import { action } from 'mobx';
import React from 'react';
import { formatWarehouseChoice, getAllVendor, getConsignor, getOwner, getWarehouse, logSubGrid } from '../../utils';
import { invoiceDetailGrid } from './invoiceDetailGrid';
import { invoiceExpressDetailGrid } from './invoiceExpressDetailGrid';
import { invoiceFilterItems } from './invoiceFilterItems';
import { mainTableButton } from './mainTableButton';
import { mainTableColumns } from './mainTableColumns';
import { receivePerson } from './receivePerson';
import type { InvoiceItem } from './types';
import { IronPrintDetailModal, IronPrintDetailModalStore } from './ironPrintDetailModal';
import { boxDetailGrid } from './boxDetailGrid';
import { BoxDetailModal, BoxDetailModel } from './boxDetailModal';

export class Store {
  constructor() {
    this.init();
  }

  @action public init = () => {
    // 快递打印标识
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/delivery/print/mark/state' })
      .then((info) => this.programme.filterItems.addDict({ courierPrintMarkState: objToDict(info.data) }));

    // 发货单类型
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/delivery/type' })
      .then((info) => this.programme.filterItems.addDict({ wmsOrderTypes: objToDict(info.data) }));

    // 是否验货
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/examine/goods/state' })
      .then((info) => this.programme.filterItems.addDict({ examineGoods: objToDict(info.data) }));

    // 发货状态
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/order/state' })
      .then((info) => this.programme.filterItems.addDict({ wmsOrderStates: objToDict(info.data) }));

    // 订单类型
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/order/type' })
      .then((info) => this.programme.filterItems.addDict({ orderTypes: objToDict(info.data) }));

    // 发货单来源
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/sale/order/origin/type' })
      .then((info) => this.programme.filterItems.addDict({ saleOrderOriginTypes: objToDict(info.data) }));

    // 是否作废
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/wms/order/cancel' })
      .then((info) => this.programme.filterItems.addDict({ cancel: objToDict(info.data) }));

    // 时效类型
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/wms/order/prescription/type' })
      .then((info) => this.programme.filterItems.addDict({ prescriptionTypes: objToDict(info.data) }));

    // 挂起状态
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/wms/order/stop/state' })
      .then((info) => this.programme.filterItems.addDict({ stopState: objToDict(info.data) }));

    // 上架状态
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/wms/order/out/of/stock/state' })
      .then((info) => this.programme.filterItems.addDict({ outOfStock: objToDict(info.data) }));

    // 平台类型
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/platform/type' })
      .then((info) => this.programme.filterItems.addDict({ platformType: objToDict(info.data) }));

    // 供应商
    getAllVendor()
      .then((info) => this.programme.filterItems.addDict({ suppliers: info }));

    // 熨烫状态
    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/order/v2/printPatternStatus' })
      .then((info) => this.programme.filterItems.addDict({ printPatternStatus: objToDict(info.data) }));

    // 仓库
    getWarehouse()
      .then((info) => {
        this.programme.filterItems.updateFilterItem([
          {
            field: 'warehouseIds',
            data: info,
            value: formatWarehouseChoice(info, 'warehouseIds'),
          },
        ]);
        this.getDataAfterWarehouseAndConsignor(formatWarehouseChoice(info, 'warehouseIds'));
      });
  };

  public getDataAfterWarehouseAndConsignor = (warehouseId: string): void => {
    // 店铺和快递公司
    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'shop,courier',
        module: '',
        warehouseId,
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, {
          shop: 'shopNames',
          courier: 'courierIds',
        });
        this.programme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });

    getOwner({ warehouseId })
      .then((data) => this.programme.filterItems.addDict({ ownerIds: data }));

    getConsignor({}, { warehouseId: warehouseId || '' })
      .then((data) => this.programme.filterItems.addDict({ buyerTenantIds: data }));
  };

  public gridModel = new MainSubStructureModel({
    buttons: mainTableButton(this),
    pageId: '1640',
    grid: {
      columns: mainTableColumns(this),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/invoice/index/main',
      sumColumns: ['totalNum'],
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<InvoiceItem>>({
          url: '/api/cloud/wms/rest/order/v2/list/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
          headers: { warehouseId: filterParams?.warehouseIds || '' },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        invoiceDetailGrid(this),
        invoiceExpressDetailGrid(),

        // @ts-ignore
        receivePerson(),
        logSubGrid(10000, {}, 'warehouseId'),
        boxDetailGrid(this),
      ],
    },
  });

  public programme = new Programme({
    gridModel: this.gridModel,
    filterItems: invoiceFilterItems(this),
    moduleName: 'egenieCloudWmsInvoice',
  });

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public ironPrintDetailModalStore: IronPrintDetailModalStore = new IronPrintDetailModalStore();

  public boxDetailStore = new BoxDetailModel();
}

const store = new Store();

export default class extends React.Component {
  render() {
    return (
      <>
        <ProgrammeComponent store={store.programme}/>
        <ExportModal store={store.exportStore}/>
        <IronPrintDetailModal ironPrintDetailModalStore={store.ironPrintDetailModalStore}/>
        <BoxDetailModal store={store.boxDetailStore}/>
      </>
    );
  }
}
