import type { FilterItemOptions } from 'egenie-utils';
import { INNER_EMPTY_STATUS, INVOICE_PLUS_SERVICE_DATA } from '../../utils';
import type { Store } from './index';

export function invoiceFilterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      data: [
        {
          value: '2',
          label: '付款时间',
        },
        {
          value: '3',
          label: '获取时间',
        },
        {
          value: '4',
          label: '快递单打印时间',
        },
        {
          value: '6',
          label: '分拣时间',
        },
        {
          value: '8',
          label: '验货时间',
        },
        {
          value: '9',
          label: '称重时间',
        },
        {
          value: '10',
          label: '上架时间',
        },
        {
          value: '11',
          label: '截止发货时间',
        },
      ],
    },
    {
      type: 'select',
      field: 'warehouseIds',
      label: '仓库',
      allowClear: false,
      onChangeCallback: (warehouseIds: string) => {
        context.getDataAfterWarehouseAndConsignor(warehouseIds);
        context.programme.filterItems.updateFilterItem([
          {
            field: 'ownerIds',
            value: undefined,
          },
          {
            field: 'shopNames',
            value: undefined,
          },
          {
            field: 'courierIds',
            value: undefined,
          },
          {
            field: 'buyerTenantIds',
            value: undefined,
          },
        ]);
      },
    },
    {
      field: 'wmsOrderNo',
      type: 'input',
      label: '发货单号',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'ownerIds',
      label: '货主',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'suppliers',
      label: '供应商',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'shopNames',
      label: '店铺',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'courierIds',
      label: '快递公司',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'wmsOrderTypes',
      label: '发货单类型',
    },
    {
      type: 'select',
      mode: 'multiple',
      label: '发货单状态',
      field: 'wmsOrderStates',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'orderTypes',
      label: '订单类型',
    },
    {
      type: 'input',
      field: 'receiverName',
      label: '收货人姓名',
    },
    {
      type: 'input',
      field: 'receiverMobile',
      label: '收货人手机',
    },
    {
      type: 'input',
      field: 'buyerNick',
      label: '买家昵称',
    },
    {
      field: 'sellerMemo',
      type: 'radio',
      label: '客服备注',
      data: [
        {
          value: INNER_EMPTY_STATUS.notEmpty.value,
          label: '备注非空',
          showInput: true,
        },
        {
          value: INNER_EMPTY_STATUS.isEmpty.value,
          label: '备注为空',
        },
      ],
    },
    {
      field: 'totalNum',
      type: 'inputNumberGroup',
      label: '宝贝数量',
    },
    {
      type: 'inputNumberGroup',
      field: 'totalSku',
      label: '宝贝条数',
    },
    {
      field: 'courierPrintMarkState',
      type: 'select',
      label: '快递打印',
    },
    {
      field: 'courierNo',
      type: 'radio',
      label: '快递单号',
      data: [
        {
          value: INNER_EMPTY_STATUS.notEmpty.value,
          label: '有快递单号',
          showInput: true,
        },
        {
          value: INNER_EMPTY_STATUS.isEmpty.value,
          label: '无快递单号',
        },
      ],
    },
    {
      field: 'examineGoods',
      type: 'select',
      label: '验货状态',
    },
    {
      field: 'saleOrderMemo',
      type: 'radio',
      label: '订单备注',
      data: [
        {
          value: INNER_EMPTY_STATUS.notEmpty.value,
          label: '备注非空',
          showInput: true,
        },
        {
          value: INNER_EMPTY_STATUS.isEmpty.value,
          label: '备注为空',
        },
      ],
    },
    {
      field: 'wmsPickingOrderNo',
      type: 'radio',
      label: '波次号',
      data: [
        {
          value: '1',
          label: '已生成',
          showInput: true,
        },
        {
          value: '0',
          label: '未生成',
        },
      ],
    },
    {
      field: 'platformOrderCode',
      type: 'input',
      label: '平台单号',
      isMultipleSearch: true,
      splitSymbol: ' ',
    },
    {
      field: 'skuNo',
      type: 'input',
      label: 'SKU编码',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'saleOrderOriginTypes',
      label: '发货单来源',
    },
    {
      type: 'select',
      field: 'prescriptionTypes',
      label: '时效类型',
    },
    {
      field: 'weight',
      type: 'radio',
      label: '重量',
      data: [
        {
          value: INNER_EMPTY_STATUS.notEmpty.value,
          label: '重量非空',
          showInput: true,
        },
        {
          value: INNER_EMPTY_STATUS.isEmpty.value,
          label: '重量为空',
        },
      ],
    },
    {
      field: 'stopState',
      type: 'select',
      label: '挂起状态',
      value: '0',
    },
    {
      field: 'cancel',
      type: 'select',
      label: '作废状态',
      value: '0',
    },
    {
      field: 'uniqueCode',
      type: 'input',
      label: '唯一码',
    },
    {
      field: 'platformType',
      type: 'select',
      label: '平台类型',
      mode: 'multiple',
    },
    {
      field: 'outOfStock',
      type: 'select',
      label: '上架状态',
    },
    {
      field: 'wmsOrderAddedServiceStates',
      type: 'select',
      mode: 'multiple',
      label: '增值服务',
      data: INVOICE_PLUS_SERVICE_DATA,
    },
    {
      field: 'suspended',
      type: 'select',
      label: '是否停发',
      data: [
        {
          value: '1',
          label: '已停发',
        },
        {
          value: '0',
          label: '未停发',
        },
      ],
    },
    {
      field: 'cancelDelivery',
      type: 'select',
      label: '是否取消发货',
      data: [
        {
          value: '1',
          label: '是',
        },
        {
          value: '0',
          label: '否',
        },
      ],
    },
    {
      field: 'platformDeliverStatus',
      type: 'select',
      label: '平台发货状态',
      data: [
        {
          value: '1',
          label: '已发货',
        },
        {
          value: '0',
          label: '未发货',
        },
      ],
    },
    {
      field: 'platformCourierNo',
      label: '平台快递单号',
      type: 'input',
    },
    {
      field: 'printPatternStatus',
      type: 'select',
      label: '烫印状态',
    },
    {
      field: 'note',
      label: '备注',
      type: 'input',
    },
    {
      field: 'courierPrintMode',
      label: '打印方式',
      type: 'select',
      data: [
        {
          value: '1',
          label: '前置',
        },
        {
          value: '2',
          label: '后置',
        },
      ],
    },
    {
      field: 'waybillCodeSource',
      label: '单号获取来源',
      type: 'select',
      data: [
        {
          value: '1',
          label: '网仓',
        },
        {
          value: '2',
          label: '货主',
        },
      ],
    },
    {
      type: 'select',
      label: '有货超时',
      field: 'deliveryWarnFlag',
      data: [
        {
          value: '0',
          label: '不超时',
        },
        {
          value: '1',
          label: '超时',
        },
      ],
    },
    {
      field: 'buyerTenantIds',
      label: '代发客户',
      type: 'select',
      mode: 'multiple',
    },
    {
      type: 'select',
      label: '优先发货',
      field: 'priority',
      data: [
        {
          value: '1',
          label: '是',
        },
        {
          value: '0',
          label: '否',
        },
      ],
    },
    {
      type: 'input',
      label: '库存归属',
      field: 'stockBelongName',
    },

    // 查询条件不在页面显示,只用来跳转带参数
    {
      type: 'select',
      label: '采购状态',
      field: 'purchaseState',
      style: { display: 'none' },
    },
    {
      field: 'oneDayInStockUnshipped',
      label: '24小时有货未发',
      type: 'select',
      style: { display: 'none' },
    },
  ];
}
