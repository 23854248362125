import { Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructure, MainSubStructureModel, request, ImgFormatter } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import type { IronPrintDetailItem, InvoiceDetailItem } from './types';

export class IronPrintDetailModalStore {
  @observable.ref public invoiceDetailItem: InvoiceDetailItem = {};

  @observable public visible = false;

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'hotStampPic',
          name: '烫印片',
          width: 80,
          formatter: ({ row }) => <ImgFormatter value={row.hotStampPic}/>,
        },
        {
          key: 'skuNo',
          name: 'sku编码',

          // width: 250,
        },
        {
          key: 'whiteBoardSkuNo',
          name: '白板sku编码',

          // width: 250,
        },
        {
          key: 'hotStampNumber',
          name: '数量',
          width: 80,
        },
        {
          key: 'size',
          name: '尺码',
          width: 80,
        },
        {
          key: 'color',
          name: '颜色',
          width: 80,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'wmsOrderHotStampDetailId',
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<IronPrintDetailItem>>({
          method: 'POST',
          url: '/api/cloud/wms/rest/order/v2/hot/stamp/detail',
          data: {
            ...rest,
            wmsOrderDetailId: this.invoiceDetailItem?.id,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  @action public handleCancel = () => {
    this.visible = false;
    this.invoiceDetailItem = {};
    this.mainSubStructureModel.gridModel.resetAll();
  };

  @action public handleOpen = (invoiceDetailItem: InvoiceDetailItem) => {
    this.visible = true;
    this.invoiceDetailItem = toJS(invoiceDetailItem);
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };
}

@observer
export class IronPrintDetailModal extends React.Component<{ ironPrintDetailModalStore: IronPrintDetailModalStore; }> {
  render() {
    const {
      ironPrintDetailModalStore: {
        handleCancel,
        visible,
        mainSubStructureModel,
      },
    } = this.props;
    return (
      <Modal
        centered
        footer={null}
        onCancel={handleCancel}
        title="烫印详情"
        visible={visible}
        width={900}
      >
        <div style={{ height: 400 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}
