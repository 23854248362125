import { Popover } from 'antd';
import React from 'react';

export function ExpressSearch({
  expressNumber,
  expressName,
}) {
  return (
    <Popover
      content={(
        <div
          id="courierNote"
          style={{
            width: '380px',
            height: '400px',
          }}
        >
          <iframe
            id="kuaidi101"
            name="kuaidi100-iframe"
            src={`https://m.kuaidi100.com/app/query/?com=${expressName}&nu=${expressNumber}&coname=yilian`} // src为指定嵌入的吐个槽页面
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      )}
      placement="right"
      title={(
        <span>
          物流信息
        </span>
      )}
      trigger="click"
    >
      <i className="icon-deliver_goods right-icon"/>
    </Popover>
  );
}
