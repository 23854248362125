import type { BaseData } from 'egenie-utils';
import { TimeStampFormatter, request } from 'egenie-utils';
import React from 'react';
import type { InvoiceDetailItem } from './types';

export function invoiceExpressDetailGrid() {
  return {
    tab: {
      name: '物流轨迹',
      value: 'expressDetail',
    },
    grid: {
      columns: [
        {
          key: 'stateType',
          name: '当前状态',
          width: 90,
        },
        {
          key: 'waybillCode',
          name: '快递单号',
          width: 300,
        },
        {
          key: 'traces',
          name: '轨迹信息',
          width: 600,
        },
        {
          key: 'statusTimeString',
          name: '轨迹时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.statusTime}/>,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'tmsCourierTraceId',
      sortByLocal: false,
      showPager: false,
      showCheckBox: false,
    },
    api: {
      onQuery: ({
        pid,
        cursorRow,
      }) => {
        return request<BaseData<InvoiceDetailItem[]>>({
          url: '/api/tms/cloud/waybill/searchTrace',
          method: 'POST',
          data: new URLSearchParams(Object.entries({ waybillCode: cursorRow.waybillCode })),
          headers: { warehouseId: cursorRow?.warehouseId || '' },
        });
      },
    },
  };
}
