export const INNER_EMPTY_STATUS = {
  notEmpty: {
    value: 'SEARCH_FOR_IS_NOT_NULL',
    label: '非空',
  },
  isEmpty: {
    value: 'SEARCH_FOR_IS_NULL',
    label: '为空',
  },
};

export const ENUM_QUALITY_TESTING_PLUS_SERVICE_NODE = {
  deliver: {
    value: '21',
    label: '发货质检',
  },
  afterSale: {
    value: '22',
    label: '售后质检',
  },
};

export const ENUM_PLUS_SERVICE_AUDIT_STATUS = {
  waitApprove: {
    value: 0,
    label: '未开通',
    color: 'warning',
  },
  approving: {
    value: 1,
    label: '待审核',
    color: 'processing',
  },
  approved: {
    value: 2,
    label: '已开通',
    color: 'success',
  },
};

export const ENUM_PLUS_SERVICE_TYPE = {
  changeTheTag: {
    value: '1',
    label: '换吊牌',
  },
  qualityTesting: {
    value: '2',
    label: '质检',
  },
  changeDecorate: {
    value: '3',
    label: '换包装',
  },
  certificate: {
    value: '4',
    label: '放合格证',
  },
  praiseCard: {
    value: '5',
    label: '放好评卡',
  },
  deliveryList: {
    value: '6',
    label: '放发货单',
  },
  ironPrint: {
    value: '7',
    label: '烫印',
  },
  waterWashingLabel: {
    value: '8',
    label: '水洗唛',
  },
  changeCollarLabel: {
    value: '9',
    label: '换领标',
  },
};

export const ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE = {
  deliveryList: ENUM_PLUS_SERVICE_TYPE.deliveryList,
  qualityTesting: ENUM_PLUS_SERVICE_TYPE.qualityTesting,
};

export const INVOICE_PLUS_SERVICE_DATA = [
  {
    value: '0',
    label: '无增值服务',
  },
].concat(Object.values(ENUM_PLUS_SERVICE_TYPE)
  .filter((item) => item.value !== ENUM_PLUS_SERVICE_TYPE.qualityTesting.value));

export const ENUM_OWNER = {
  replaceDeliverOwnerType: 3,
  selfStockType: 8,
  chooseStyleOwnerId: -3,
};
