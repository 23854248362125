import { Form, Input, Modal, Select } from 'antd';
import type { BaseData } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-utils';
import React from 'react';

interface ForwardModalProps {
  ownerId: string;
  orderType: string;
  buyerTenantId: string;
  warehouseId: string;
  callback: (params: { targetWarehouseId: string; note: string; }) => Promise<unknown>;
  onCancel: (...arg: any[]) => any;
}

export function ForwardModal({
  callback,
  onCancel,
  warehouseId,
  buyerTenantId,
  ownerId,
  orderType,
}: ForwardModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  const [
    data,
    setData,
  ] = React.useState<ValueAndLabelData>([]);
  React.useEffect(() => {
    request<BaseData<Array<{ warehouseId?: string; warehouseName?: string; }>>>({
      url: '/api/cloud/wms/rest/order/v2/enableWarehouse',
      method: 'post',
      data: {
        ownerId,
        buyerTenantId,
        orderType,
        targetWarehouseId: warehouseId,
      },
    })
      .then((info) => setData((info.data || []).map((item) => ({
        value: item.warehouseId,
        label: item.warehouseName,
      }))));
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="转单发货"
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{}}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="目标仓库"
          name="targetWarehouseId"
          rules={[
            {
              required: true,
              message: '请选择目标仓库',
            },
          ]}
        >
          <Select
            allowClear
            autoFocus
            notFoundContent="没有数据"
            optionFilterProp="label"
            options={data}
            placeholder="请选择目标仓库"
            showSearch
          />
        </Form.Item>
        <Form.Item
          label="备注"
          name="note"
        >
          <Input
            allowClear
            placeholder="请填写备注"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
